body {
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;
}

pre {
 white-space: pre-wrap;
 white-space: -moz-pre-wrap;
 white-space: -pre-wrap;
 white-space: -o-pre-wrap;
 word-wrap: break-word;
}

div [aria-hidden="true"] {
  border: inherit;
}

div [type="radio"] > span {
  background-color: white;
}

.no-current-page[aria-current="page"] {
  font-weight: inherit !important;
  color: inherit !important;
}

.proposal-description > *:not(:last-child) {
  margin-bottom: 1em;
}

.proposal-description > * {
  font-family: "DM Sans";
  word-break: break-word;
}

/* progress bar */
.gray-bar {
  background-color: #718096;
}

.gray-bar > div {
  background-color: #718096 !important;
}

.dual-bar {
  background-color: #f44061 !important;
}

.dual-bar > div {
  background-color: #25c9a1 !important;
}

.in-favor-bar {
  background-color: transparent !important;
}

.in-favor-bar > div {
  background-color: #00e6cd !important;
}

.against-bar {
  background-color: transparent !important;
}

.against-bar > div {
  background-color: #cbd5e0 !important;
}

/* clamp text */
.clamp-text-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clamp-text-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clamp-text-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

body::-webkit-scrollbar {
  border-top-right-radius: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom-right-radius: 1rem;
}

body::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #718096;
}

/* scrollbar */
.scrollbar::-webkit-scrollbar {
  border-top-right-radius: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom-right-radius: 1rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #718096;
}

.dropshadow {
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1));
}

[aria-current="page"] {
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  color: #4a5568 !important;
}

.card-paginator {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
}

.card-paginator svg {
  color: #a0aec0;
}

.card-paginator li.previous {
  position: absolute;
  left: 0;
}

.card-paginator li.next {
  position: absolute;
  right: 0;
}

.card-paginator-link--active {
  color: #171923;
}

.card-paginator-link {
  color: #a0aec0;
  margin-right: 0.5rem;
}

.card-paginator li:nth-last-of-type(2) a {
  margin-right: 0;
}

label [aria-hidden="true"] {
  border: 1px solid black;
}

.rotate {
  animation: hourglass 5s ease infinite;
}

@keyframes hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@keyframes up-and-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3em);
  }
  100% {
    transform: translateY(0);
  }
}

.custom-line-chart-tooltip {
  background: #fff;
  opacity: 0.9;
  box-shadow: 0px 4px 14px rgba(159, 162, 180, 0.2);
  border-radius: 4px;
}

.no-underline:hover {
  text-decoration: none !important;
}

.no-box-shadow:focus {
  box-shadow: none !important;
}

*[data-hover] {
  border: inherit !important;
}

dd {
  display: flex;
  align-items: center;
}
dd > *:not(:last-child) {
  margin-right: 0.5rem;
}

.no-margin {
  margin: 0 !important;
}

.cryptex-icon-cls-1 {
  fill: #1d1d3c;
}
.cryptex-icon-cls-2 {
  fill: url(#linear-gradient);
}
.cryptex-icon-cls-3 {
  fill: #fff;
}
.cryptex-icon-cls-4 {
  fill: #a440f2;
}
.cryptex-icon-cls-5 {
  opacity: 0.4;
  fill: url(#linear-gradient-2);
}
.cryptex-icon-cls-6 {
  fill: url(#linear-gradient-3);
}

.conjure-icon-1 {
  fill: #fdc433;
}
.conjure-icon-2 {
  fill: #03b6c2;
}
.conjure-icon-3 {
  fill: #fe6d6f;
}

.rari-cls-1 {
  stroke: #000;
}
.rari-cls-1,
.rari-cls-2,
.rari-cls-3,
.rari-cls-4 {
  stroke-miterlimit: 10;
}
.rari-cls-2,
.rari-cls-3,
.rari-cls-4 {
  stroke-width: 60px;
}
.rari-cls-2 {
  stroke: url(#White_Black);
}
.rari-cls-3 {
  stroke: url(#White_Black-2);
}
.rari-cls-4 {
  stroke: url(#White_Black-3);
}

.ProseMirror {
  outline: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror p.is-editor-empty:first-child::after {
  content: "It can be multi-line!";
  float: left;
  color: #adb5bd;
  pointer-events: none;
}

.ProseMirror ul, .ProseMirror ol {
  margin-left: 18px;
}

.ProseMirror ul ul, .ProseMirror ol ol {
  margin-left: 18px;
}

.uppy-DragDrop-inner svg {
  display: none;
}

.uppy-DragDrop-label {
  font-size: 20px;
  font-weight: "semibold";
}

.uppy-DragDrop-note {
  font-size: 12px;
}


/* react-md-editor */
.w-md-editor-toolbar {
  height: 60px !important;
  border: 0 !important;
}

.w-md-editor-bar {
  display: none !important;
}

.w-md-editor-show-edit {
  height: 375px !important;
  border: 0 !important;
}

.w-md-editor-text {
  border: 1px solid rgb(235, 240, 246) 
}

.w-md-editor-show-edit {
  box-shadow: none !important;
}

.w-md-editor .title {
  line-height: 10px !important;
  font-size: unset !important;
  font-weight: unset !important;
}

.w-md-editor-text-input {
  overflow: hidden;
  -webkit-text-fill-color: transparent;
}

.w-md-editor-toolbar {
  padding-left: 0 !important;
}

.w-md-editor-toolbar li > button {
  padding: 8px !important;
  margin-right: 5px !important;
  height: 42px !important;
  width: 40px !important;
  background-color: #EDF2F6 !important;
}

.w-md-editor-toolbar li > button:hover {
  background-color: #dfe5e9 !important;
}



/* animations */
@keyframes gradient {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.waving-gradient {
  background: linear-gradient(-45deg, #FFEEF8, #F1F8FF, #FFEEF8, #F1F8FF);
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
}

.rotation {
  animation: rotation 3s ease infinite;
}

.BeaconFabButtonFrame {
  display: block;
}

@media (max-width: 30em) {
  .BeaconFabButtonFrame {
    display: none;
  }
}

.radius-md {
  border-radius: 0.375rem !important;
}

.gray-card-onboarding-color {
  color: #2D3748 !important;
}

.no-top-margin {
  margin-top: unset !important;
}

.no-left-margin {
  margin-left: unset !important;
}

.fill-color {
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.background-clip-text {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.home-border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to left, hsla(255, 66%, 87%, 1), hsla(255, 100%, 95%, 1));
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.home-table-mask {
  -webkit-mask: linear-gradient(to bottom, black 50%, transparent);
}

.governance-card-animation {
  animation-name: move-vertically-upwards; 
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -14s);
}

@media (min-width: 30em) {
  .governance-card-animation {
    animation-delay: calc(var(--scroll) * -12s);
  }
}

@keyframes move-vertically-upwards {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-996px);
  }
}

:root, .light-theme {
  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98.0%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94.0%);
  --blue5: hsl(209, 95.0%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15.0%);
}
.dark-theme {
  --blue1: hsl(212, 35.0%, 9.2%);
  --blue2: hsl(216, 50.0%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98.0%, 95.8%);
}
:root, .light-theme {
  --slate1: hsl(206, 30.0%, 98.8%);
  --slate2: hsl(210, 16.7%, 97.6%);
  --slate3: hsl(209, 13.3%, 95.3%);
  --slate4: hsl(209, 12.2%, 93.2%);
  --slate5: hsl(208, 11.7%, 91.1%);
  --slate6: hsl(208, 11.3%, 88.9%);
  --slate7: hsl(207, 11.1%, 85.9%);
  --slate8: hsl(205, 10.7%, 78.0%);
  --slate9: hsl(206, 6.0%, 56.1%);
  --slate10: hsl(206, 5.8%, 52.3%);
  --slate11: hsl(206, 6.0%, 43.5%);
  --slate12: hsl(206, 24.0%, 9.0%);
}
.dark-theme {
  --slate1: hsl(200, 7.0%, 8.8%);
  --slate2: hsl(195, 7.1%, 11.0%);
  --slate3: hsl(197, 6.8%, 13.6%);
  --slate4: hsl(198, 6.6%, 15.8%);
  --slate5: hsl(199, 6.4%, 17.9%);
  --slate6: hsl(201, 6.2%, 20.5%);
  --slate7: hsl(203, 6.0%, 24.3%);
  --slate8: hsl(207, 5.6%, 31.6%);
  --slate9: hsl(206, 6.0%, 43.9%);
  --slate10: hsl(206, 5.2%, 49.5%);
  --slate11: hsl(206, 6.0%, 63.0%);
  --slate12: hsl(210, 6.0%, 93.0%);
}
:root, .light-theme {
  --grass1: hsl(116, 50.0%, 98.9%);
  --grass2: hsl(120, 60.0%, 97.1%);
  --grass3: hsl(120, 53.6%, 94.8%);
  --grass4: hsl(121, 47.5%, 91.4%);
  --grass5: hsl(122, 42.6%, 86.5%);
  --grass6: hsl(124, 39.0%, 79.7%);
  --grass7: hsl(126, 37.1%, 70.2%);
  --grass8: hsl(131, 38.1%, 56.3%);
  --grass9: hsl(131, 41.0%, 46.5%);
  --grass10: hsl(132, 43.1%, 42.2%);
  --grass11: hsl(133, 50.0%, 32.5%);
  --grass12: hsl(130, 30.0%, 14.9%);
}
:root, .light-theme {
  --cyan1: hsl(185, 60.0%, 98.7%);
  --cyan2: hsl(185, 73.3%, 97.1%);
  --cyan3: hsl(186, 70.2%, 94.4%);
  --cyan4: hsl(186, 63.8%, 90.6%);
  --cyan5: hsl(187, 58.3%, 85.4%);
  --cyan6: hsl(188, 54.6%, 78.4%);
  --cyan7: hsl(189, 53.7%, 68.7%);
  --cyan8: hsl(189, 60.3%, 52.5%);
  --cyan9: hsl(190, 95.0%, 39.0%);
  --cyan10: hsl(191, 91.2%, 36.8%);
  --cyan11: hsl(192, 85.0%, 31.0%);
  --cyan12: hsl(192, 88.0%, 12.5%);
}
:root, .light-theme {
  --amber1: hsl(39, 70.0%, 99.0%);
  --amber2: hsl(40, 100%, 96.5%);
  --amber3: hsl(44, 100%, 91.7%);
  --amber4: hsl(43, 100%, 86.8%);
  --amber5: hsl(42, 100%, 81.8%);
  --amber6: hsl(38, 99.7%, 76.3%);
  --amber7: hsl(36, 86.1%, 67.1%);
  --amber8: hsl(35, 85.2%, 55.1%);
  --amber9: hsl(39, 100%, 57.0%);
  --amber10: hsl(35, 100%, 55.5%);
  --amber11: hsl(30, 100%, 34.0%);
  --amber12: hsl(20, 80.0%, 17.0%);
}
:root, .light-theme {
  --red1: hsl(359, 100%, 99.4%);
  --red2: hsl(359, 100%, 98.6%);
  --red3: hsl(360, 100%, 96.8%);
  --red4: hsl(360, 97.9%, 94.8%);
  --red5: hsl(360, 90.2%, 91.9%);
  --red6: hsl(360, 81.7%, 87.8%);
  --red7: hsl(359, 74.2%, 81.7%);
  --red8: hsl(359, 69.5%, 74.3%);
  --red9: hsl(358, 75.0%, 59.0%);
  --red10: hsl(358, 69.4%, 55.2%);
  --red11: hsl(358, 65.0%, 48.7%);
  --red12: hsl(354, 50.0%, 14.6%);
}


/* Add styles on popover link in markdown editor */
div[data-radix-popper-content-wrapper] {
  background-color: white;
  border-color: #ccc;
  border-width: 1px;
  border-radius: 6px;
  padding: 3px 4px 3px 6px;
}

.prose {
  font-family: "DM Sans";
}

.prose > h1 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.prose > h2 {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0.3em;
  margin-top: 0.5em;
}

.prose > h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0.3em;
  margin-top: 0.5em;
}

.prose > p {
  margin-bottom: 0.5em;
}

.prose > p > code {
  margin-bottom: 3px;
  margin-top: 10px;
}

.prose > blockquote {
  background-color: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.7em 10px 8px 10px;
}



.prose a {
  color: #725BFF
}

.prose > ol, ul {
  padding-left: 20px;
  margin-left: 16px;
  margin-bottom: 16px;
}

ul > li {
  list-style-type: circle;
}


:root, ._light-theme_13nbk_1 {
  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98.0%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94.0%);
  --blue5: hsl(209, 95.0%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15.0%);
}
._dark-theme_13nbk_1 {
  --blue1: hsl(212, 35.0%, 9.2%);
  --blue2: hsl(216, 50.0%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98.0%, 95.8%);
}
:root, ._light-theme_13nbk_1 {
  --slate1: hsl(206, 30.0%, 98.8%);
  --slate2: hsl(210, 16.7%, 97.6%);
  --slate3: hsl(209, 13.3%, 95.3%);
  --slate4: hsl(209, 12.2%, 93.2%);
  --slate5: hsl(208, 11.7%, 91.1%);
  --slate6: hsl(208, 11.3%, 88.9%);
  --slate7: hsl(207, 11.1%, 85.9%);
  --slate8: hsl(205, 10.7%, 78.0%);
  --slate9: hsl(206, 6.0%, 56.1%);
  --slate10: hsl(206, 5.8%, 52.3%);
  --slate11: hsl(206, 6.0%, 43.5%);
  --slate12: hsl(206, 24.0%, 9.0%);
}
._dark-theme_13nbk_1 {
  --slate1: hsl(200, 7.0%, 8.8%);
  --slate2: hsl(195, 7.1%, 11.0%);
  --slate3: hsl(197, 6.8%, 13.6%);
  --slate4: hsl(198, 6.6%, 15.8%);
  --slate5: hsl(199, 6.4%, 17.9%);
  --slate6: hsl(201, 6.2%, 20.5%);
  --slate7: hsl(203, 6.0%, 24.3%);
  --slate8: hsl(207, 5.6%, 31.6%);
  --slate9: hsl(206, 6.0%, 43.9%);
  --slate10: hsl(206, 5.2%, 49.5%);
  --slate11: hsl(206, 6.0%, 63.0%);
  --slate12: hsl(210, 6.0%, 93.0%);
}
:root, ._light-theme_13nbk_1 {
  --grass1: hsl(116, 50.0%, 98.9%);
  --grass2: hsl(120, 60.0%, 97.1%);
  --grass3: hsl(120, 53.6%, 94.8%);
  --grass4: hsl(121, 47.5%, 91.4%);
  --grass5: hsl(122, 42.6%, 86.5%);
  --grass6: hsl(124, 39.0%, 79.7%);
  --grass7: hsl(126, 37.1%, 70.2%);
  --grass8: hsl(131, 38.1%, 56.3%);
  --grass9: hsl(131, 41.0%, 46.5%);
  --grass10: hsl(132, 43.1%, 42.2%);
  --grass11: hsl(133, 50.0%, 32.5%);
  --grass12: hsl(130, 30.0%, 14.9%);
}
:root, ._light-theme_13nbk_1 {
  --cyan1: hsl(185, 60.0%, 98.7%);
  --cyan2: hsl(185, 73.3%, 97.1%);
  --cyan3: hsl(186, 70.2%, 94.4%);
  --cyan4: hsl(186, 63.8%, 90.6%);
  --cyan5: hsl(187, 58.3%, 85.4%);
  --cyan6: hsl(188, 54.6%, 78.4%);
  --cyan7: hsl(189, 53.7%, 68.7%);
  --cyan8: hsl(189, 60.3%, 52.5%);
  --cyan9: hsl(190, 95.0%, 39.0%);
  --cyan10: hsl(191, 91.2%, 36.8%);
  --cyan11: hsl(192, 85.0%, 31.0%);
  --cyan12: hsl(192, 88.0%, 12.5%);
}
:root, ._light-theme_13nbk_1 {
  --amber1: hsl(39, 70.0%, 99.0%);
  --amber2: hsl(40, 100%, 96.5%);
  --amber3: hsl(44, 100%, 91.7%);
  --amber4: hsl(43, 100%, 86.8%);
  --amber5: hsl(42, 100%, 81.8%);
  --amber6: hsl(38, 99.7%, 76.3%);
  --amber7: hsl(36, 86.1%, 67.1%);
  --amber8: hsl(35, 85.2%, 55.1%);
  --amber9: hsl(39, 100%, 57.0%);
  --amber10: hsl(35, 100%, 55.5%);
  --amber11: hsl(30, 100%, 34.0%);
  --amber12: hsl(20, 80.0%, 17.0%);
}
:root, ._light-theme_13nbk_1 {
  --red1: hsl(359, 100%, 99.4%);
  --red2: hsl(359, 100%, 98.6%);
  --red3: hsl(360, 100%, 96.8%);
  --red4: hsl(360, 97.9%, 94.8%);
  --red5: hsl(360, 90.2%, 91.9%);
  --red6: hsl(360, 81.7%, 87.8%);
  --red7: hsl(359, 74.2%, 81.7%);
  --red8: hsl(359, 69.5%, 74.3%);
  --red9: hsl(358, 75.0%, 59.0%);
  --red10: hsl(358, 69.4%, 55.2%);
  --red11: hsl(358, 65.0%, 48.7%);
  --red12: hsl(354, 50.0%, 14.6%);
}
._bold_13nbk_10 {
  font-weight: bold;
}
._italic_13nbk_14 {
  font-style: italic;
}
._underline_13nbk_18 {
  text-decoration: underline;
}
._bold_13nbk_10 {
    font-weight: 700
}
._italic_13nbk_14 {
    font-style: italic
}
._underline_13nbk_18 {
    text-decoration: underline
}
._strikethrough_13nbk_34 {
    text-decoration: line-through
}
._underlineStrikethrough_13nbk_38 {
    text-decoration: underline line-through
}
._subscript_13nbk_42 {
    font-size: .8em;
    vertical-align: sub!important
}
._superscript_13nbk_47 {
    font-size: .8em;
    vertical-align: super
}
._code_13nbk_52 {
    padding: 1px .25rem;
    font-family: var(--font-mono);
    font-size: 94%
}
._nestedListItem_13nbk_59 {
  list-style:none;
}
._admonitionDanger_13nbk_63, ._admonitionInfo_13nbk_63, ._admonitionNote_13nbk_63, ._admonitionTip_13nbk_63, ._admonitionCaution_13nbk_63 {
  padding: var(--spacing-2);
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  border-left: 3px solid var(--admonitionBorder);
  background-color: var(--admonitionBg);
}
._admonitionInfo_13nbk_63 {
  --admonitionBorder: var(--admonitionInfoBorder);
  --admonitionBg: var(--admonitionInfoBg);
}
._admonitionTip_13nbk_63 {
  --admonitionBorder: var(--admonitionTipBorder);
  --admonitionBg: var(--admonitionTipBg);
}
._admonitionCaution_13nbk_63 {
  --admonitionBorder: var(--admonitionCautionBorder);
  --admonitionBg: var(--admonitionCautionBg);
}
._admonitionDanger_13nbk_63 {
  --admonitionBorder: var(--admonitionDangerBorder);
  --admonitionBg: var(--admonitionDangerBg);
}
._admonitionNote_13nbk_63 {
  --admonitionBorder: var(--admonitionNoteBorder);
  --admonitionBg: var(--admonitionNoteBg);
}


/* Liveblocks custom styles */
.lb-thread-comments {
  border-width: 0px;
  border-bottom: 0px;
}

.lb-threads .lb-thread:not(:first-child) {
  border-top: 1px solid var(--chakra-colors-gray-200);
}

.lb-thread-comments .lb-composer {
  border: 0px;
}

.lb-thread-comments .lb-comment:not(:first-child) {
  padding-left: 4em;
  font-size: 0.9em
}

.lb-thread-comments .lb-comment-author:hover {
  cursor: pointer;
  text-decoration: underline;
}

.lb-root form.lb-composer {
  padding-left: 2.4em;
}

.lb-thread-comments form.lb-composer {
  padding-left: 0em;
}

.lb-composer-action {
  background-color: var(--chakra-colors-gray-700) !important;
  color: white !important;
  font-weight: bold !important;
  padding: 4px 8px !important;
}

.lb-composer-action[type="submit"]::before {
  content: "Post ";
  padding-right: 2px;
}

.lb-composer-action[type="button"]::before {
  content: "Cancel ";
  padding-right: 2px;
}

.lb-composer-editor-actions button {
  display: none !important;
}


/* Chakra UI fixes */

/* Fix for Chakra UI's checkbox */
.chakra-checkbox__control {
  border: 1px solid var(--chakra-colors-purple-500) !important
}

/* Fix for Chakra UI's radio */
.chakra-radio__control {
  border: 1px solid var(--chakra-colors-purple-500) !important
}

.chakra-radio__control[data-checked] {
  background-color: var(--chakra-colors-purple-500) !important
}


/* markdown-viewer: Markdown table styles */
.proposal-description {
  overflow-x: auto;
}

.markdown-viewer table, .markdown-viewer tr, .markdown-viewer td, .markdown-viewer th {
  border: 0.5px solid #D0D5DD;
}

.markdown-viewer th {
  background-color: #e6e6e6;
}

.markdown-viewer td {
  padding: 6px 9px;
  word-break: keep-all;
}

._editorRoot_lug8m_37 {
  --accentBase: var(--blue1);
  --accentBgSubtle: var(--blue2);
  --accentBg: var(--blue3);
  --accentBgHover: var(--blue4);
  --accentBgActive: var(--blue5);
  --accentLine: var(--blue6);
  --accentBorder: var(--blue7);
  --accentBorderHover: var(--blue8);
  --accentSolid: var(--blue9);
  --accentSolidHover: var(--blue10);
  --accentText: var(--blue11);
  --accentTextContrast: var(--blue12);

  --baseBase: var(--slate1);
  --baseBgSubtle: var(--slate2);
  --baseBg: var(--slate3);
  --baseBgHover: var(--slate4);
  --baseBgActive: var(--slate5);
  --baseLine: var(--slate6);
  --baseBorder: var(--slate7);
  --baseBorderHover: var(--slate8);
  --baseSolid: var(--slate9);
  --baseSolidHover: var(--slate10);
  --baseText: var(--slate11);
  --baseTextContrast: var(--slate12);

  --admonitionTipBg: var(--cyan4);
  --admonitionTipBorder: var(--cyan8);

  --admonitionInfoBg: var(--grass4);
  --admonitionInfoBorder: var(--grass8);

  --admonitionCautionBg: var(--amber4);
  --admonitionCautionBorder: var(--amber8);

  --admonitionDangerBg: var(--red4);
  --admonitionDangerBorder: var(--red8);

  --admonitionNoteBg: var(--slate4);
  --admonitionNoteBorder: var(--slate8);

  --spacing-0: 0px;
  --spacing-px: 1px;
  --spacing-0_5: 0.125rem;
  --spacing-1: 0.25rem;
  --spacing-1_5: 0.375rem;
  --spacing-2: 0.5rem;
  --spacing-2_5: 0.625rem;
  --spacing-3: 0.75rem;
  --spacing-3_5: 0.875rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-28: 7rem;
  --spacing-32: 8rem;
  --spacing-36: 9rem;
  --spacing-40: 10rem;
  --spacing-44: 11rem;
  --spacing-48: 12rem;
  --spacing-52: 13rem;
  --spacing-56: 14rem;
  --spacing-60: 15rem;
  --spacing-64: 16rem;
  --spacing-72: 18rem;
  --spacing-80: 20rem;
  --spacing-96: 24rem;

  --radius-none: 0px;
  --radius-small: var(--spacing-0_5);
  --radius-base: var(--spacing-1);
  --radius-medium: var(--spacing-1_5);
  --radius-large: var(--spacing-2);
  --radius-extra-large: var(--spacing-3);
  --radius-full: 9999px;

  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --font-body: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  --text-base: 1rem;
  --text-sm: 0.875rem;
  --text-xs: 0.75rem;
  --text-xxs: 0.6rem;

  font-family: var(--font-body);
  color: var(--baseText);
}

/** Code mirror */
.mdxeditor .cm-editor {
  --sp-font-mono: var(--font-mono);
  --sp-font-body: var(--font-body);
  padding: var(--sp-space-4) 0;
}
.mdxeditor .cm-scroller {
  padding: 0 !important;
}
.mdxeditor .sp-wrapper {
  border: 1px solid var(--baseLine);
  border-radius: var(--radius-medium);
  overflow: hidden;
}
.mdxeditor .sp-layout {
  border: none;
}
.mdxeditor .sp-cm pre {
  white-space: break-spaces;
  word-break: break-word;
  overflow-wrap: anywhere;
  flex-shrink: 1;
}
/** Diff viewer */
.mdxeditor .cm-mergeView .cm-scroller {
  font-family: var(--font-mono);
  line-height: 1.3rem;
  font-size: var(--text-xs);
}
/** Diff viewer */
.mdxeditor .cm-sourceView .cm-scroller {
  font-family: var(--font-mono);
  line-height: 1.3rem;
  font-size: var(--text-xs);
}
.mdxeditor .cm-gutters {
  background: transparent;
  font-size: var(--text-xxs);
}

._nestedListItem_lug8m_141 {
  list-style: none;
}

._popupContainer_lug8m_1127 {
  position: relative;
  z-index: 20;
}

._dialogOverlay_lug8m_787 {
  position: fixed;
  inset: 0;
  animation: _overlayShow_uazmk_1 .15s cubic-bezier(.16,1,.3,1);
  background-color: var(--baseBase);
  z-index: 51;
  opacity: .5;
}

._dialogContent_lug8m_543, ._largeDialogContent_uazmk_614 {
  position: fixed;
  top: 50%;
  background-color: white;
  border: 1px solid #ccc;
  padding: var(--spacing-4);
  left: 50%;
  transform: translate(-50%, -50%);
  animation: _contentShow_lug8m_1 .15s cubic-bezier(.16,1,.3,1);
  z-index: 52;
}

form._multiFieldForm_lug8m_1101, form._multiFieldForm_lug8m_1101 ._formField_lug8m_1107 {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

._primaryButton_lug8m_453 {
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid #000;
  background-color: #000;
  color: var(--baseBase);
  font-size: var(--text-sm);
  border-radius: var(--radius-medium);
}

._secondaryButton_lug8m_453 {
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid #000;
  font-size: var(--text-sm);
  border-radius: var(--radius-medium);
}

._textInput_lug8m_1093 {
  all: unset;
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  padding: var(--spacing-2) var(--spacing-3);
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

._toolbarRoot_lug8m_145 {
  /* border: var(--spacing-px) solid var(--baseBorder); */
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
  border-radius: var(--radius-medium);
  padding: var(--spacing-2) var(--spacing-2);
  align-items: center;
  overflow-x: auto;
  position: sticky;
  top: 0;
  background-color: var(--baseBgSubtle);
  width: inherit;
}

._toolbarRoot_lug8m_145 div[role="separator"] {
  margin: var(--spacing-2) var(--spacing-2);
  border-left: 1px solid var(--baseBorder);
  border-right: 1px solid var(--baseBase);
  height: var(--spacing-4);
}

._readOnlyToolbarRoot_lug8m_168 {
  pointer-events: none;
  background: var(--baseBase);
}

._readOnlyToolbarRoot_lug8m_168 > div {
  opacity: 0.5;
}

._toolbarModeSwitch_lug8m_176 {
  opacity: 1 !important;
  margin-left: auto;
  align-self: stretch;
  align-items: stretch;
  display: flex;
  border: 1px solid var(--baseBg);
  border-radius: var(--radius-medium);
  font-size: var(--text-xs);
}

._toolbarModeSwitch_lug8m_176 ._toolbarToggleItem_lug8m_186 {
  padding-inline-end: var(--spacing-4);
  padding-inline-start: var(--spacing-4);
}

._toolbarModeSwitch_lug8m_176 ._toolbarToggleItem_lug8m_186:active,
._toolbarModeSwitch_lug8m_176 ._toolbarToggleItem_lug8m_186[data-state="on"] {
  background-color: var(--baseBorder);
}

._toolbarGroupOfGroups_lug8m_196 {
  display: flex;
  margin: 0 var(--spacing-1);
}

._toolbarToggleSingleGroup_lug8m_201:first-of-type
  ._toolbarToggleItem_lug8m_186:only-child,
._toolbarToggleSingleGroup_lug8m_201:only-child
  ._toolbarToggleItem_lug8m_186:first-child,
._toolbarModeSwitch_lug8m_176 ._toolbarToggleItem_lug8m_186:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}

._toolbarToggleSingleGroup_lug8m_201:last-of-type
  ._toolbarToggleItem_lug8m_186:only-child,
._toolbarToggleSingleGroup_lug8m_201:only-child
  ._toolbarToggleItem_lug8m_186:last-child,
._toolbarModeSwitch_lug8m_176 ._toolbarToggleItem_lug8m_186:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarToggleItem_lug8m_186,
._toolbarButton_lug8m_215 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
}

._toolbarToggleItem_lug8m_186 svg,
._toolbarButton_lug8m_215 svg {
  display: block;
}

._toolbarToggleItem_lug8m_186:hover,
._toolbarButton_lug8m_215:hover {
  background-color: var(--baseBgHover);
}

._toolbarToggleItem_lug8m_186:active svg,
._toolbarButton_lug8m_215:active svg {
  transform: translate(1px, 1px);
}

._toolbarToggleItem_lug8m_186[data-state="on"],
._toolbarButton_lug8m_215[data-state="on"],
._toolbarToggleItem_lug8m_186:active,
._toolbarButton_lug8m_215:active {
  background-color: var(--accentBgActive);
}

._toolbarToggleItem_lug8m_186[data-disabled],
._toolbarButton_lug8m_215[data-disabled] {
  pointer-events: none;
  color: var(--baseBorderHover);
}

._toolbarButton_lug8m_215 {
  border-radius: var(--radius-base);
}

._activeToolbarButton_lug8m_245 {
  color: var(--accentText);
}

._toolbarToggleSingleGroup_lug8m_201 {
  white-space: nowrap;
}

._toolbarNodeKindSelectContainer_lug8m_253,
._toolbarButtonDropdownContainer_lug8m_254,
._toolbarCodeBlockLanguageSelectContent_lug8m_255,
._selectContainer_lug8m_256 {
  z-index: 3;
  width: var(--spacing-36);
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
  background-color: var(--baseBgHover);
  font-size: var(--text-sm);
}

._toolbarButtonDropdownContainer_lug8m_254 {
  border-top-right-radius: var(--radius-base);
}

._toolbarNodeKindSelectTrigger_lug8m_270,
._toolbarButtonSelectTrigger_lug8m_271,
._selectTrigger_lug8m_278,
._selectTrigger_lug8m_345 {
  border: 0;
  background-color: transparent;
  display: flex;
  color: inherit;
  align-items: center;
  width: var(--spacing-36);
  padding: var(--spacing-1);
  padding-inline-start: var(--spacing-4);
  padding-inline-end: var(--spacing-2);
  border-radius: var(--radius-medium);
  white-space: nowrap;
  flex-wrap: nowrap;
  font-size: var(--text-sm);
}

._toolbarNodeKindSelectTrigger_lug8m_270:hover,
._toolbarButtonSelectTrigger_lug8m_271:hover,
._selectTrigger_lug8m_278:hover,
._selectTrigger_lug8m_345:hover {
  background-color: var(--baseBgHover);
}

._toolbarNodeKindSelectTrigger_lug8m_270[data-state="open"],
._toolbarButtonSelectTrigger_lug8m_271[data-state="open"],
._selectTrigger_lug8m_278[data-state="open"] {
  border-bottom-right-radius: var(--radius-none);
  border-bottom-left-radius: var(--radius-none);
  background-color: var(--baseBgHover);
}
._selectTrigger_lug8m_278[data-placeholder] > span:first-child {
  color: var(--baseBorderHover);
}

/** used in the sandpack */
._toolbarButtonSelectTrigger_lug8m_271 {
  width: auto;
  padding-inline-start: var(--spacing-2);
  padding-inline-end: var(--spacing-1);
}

._toolbarCodeBlockLanguageSelectTrigger_lug8m_308,
._toolbarCodeBlockLanguageSelectContent_lug8m_255 {
  width: var(--spacing-48);
}

._toolbarNodeKindSelectItem_lug8m_314,
._selectItem_lug8m_315 {
  cursor: default;
  display: flex;
  padding: var(--spacing-2) var(--spacing-4);
}

._toolbarNodeKindSelectItem_lug8m_314[data-highlighted],
._selectItem_lug8m_315[data-highlighted] {
  background-color: var(--baseBgSubtle);
}

._toolbarNodeKindSelectItem_lug8m_314[data-state="checked"],
._selectItem_lug8m_315[data-state="checked"] {
  background-color: var(--accentBgActive);
}

._toolbarNodeKindSelectItem_lug8m_314[data-highlighted],
._selectItem_lug8m_315[data-highlighted] {
  outline: none;
}

._toolbarNodeKindSelectItem_lug8m_314:last-child,
._selectItem_lug8m_315:last-child {
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarNodeKindSelectDropdownArrow_lug8m_339,
._selectDropdownArrow_lug8m_345 {
  margin-left: auto;
}

._contentEditable_lug8m_351 {
  box-sizing: border-box;
  width: 100%;
  min-height: 300px;
  color: var(--baseTextContrast);
  padding: var(--spacing-3);
}

._contentEditable_lug8m_351:focus {
  outline: none;
}

._sandpackWrapper_lug8m_354 {
  margin-bottom: var(--spacing-5);
}

._frontmatterWrapper_lug8m_358 {
  border-radius: var(--radius-medium);
  padding: var(--spacing-3);
  background-color: var(--baseBgSubtle);
}

._frontmatterWrapper_lug8m_358[data-expanded="true"] {
  margin-bottom: var(--spacing-10);
}

._frontmatterToggleButton_lug8m_370 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  font-size: var(--text-sm);
}

._propertyPanelTitle_lug8m_376 {
  font-size: var(--text-xs);
  font-weight: 400;
  margin: 0;
  padding-top: var(--spacing-2);
  padding-left: var(--spacing-2);
}

._propertyEditorTable_lug8m_384 {
  table-layout: fixed;
  border-spacing: var(--spacing-2);
}

._propertyEditorTable_lug8m_384 th {
  text-align: left;
  font-size: var(--text-sm);
  padding: var(--spacing-2) var(--spacing-3);
}

._propertyEditorTable_lug8m_384 col:nth-child(1) {
  width: 30%;
}

._propertyEditorTable_lug8m_384 col:nth-child(2) {
  width: 70%;
}

._propertyEditorTable_lug8m_384 td:last-child ._iconButton_lug8m_401 {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
}

._propertyEditorTable_lug8m_384 ._readOnlyColumnCell_lug8m_406 {
  padding-left: 0;
}

._propertyEditorLabelCell_lug8m_411 {
  font-weight: 400;
}

._readOnlyColumnCell_lug8m_406 {
  padding-left: 0;
}

._buttonsFooter_lug8m_419 {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-2);
}

._propertyEditorInput_lug8m_425 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  font-size: var(--text-sm);
}

._iconButton_lug8m_401 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  color: var(--baseText);
}

._iconButton_lug8m_401:hover {
  color: var(--accentText);
}

._iconButton_lug8m_401:disabled,
._iconButton_lug8m_401:disabled:hover {
  color: var(--baseLine);
}

._primaryButton_lug8m_446,
._secondaryButton_lug8m_446 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid var(--accentBorder);
  background-color: var(--accentSolidHover);
  color: var(--baseBase);
  font-size: var(--text-sm);
  border-radius: var(--radius-medium);
}

._primaryButton_lug8m_446:disabled,
._secondaryButton_lug8m_446:disabled {
  background: var(--accentLine);
  border-color: var(--accentBg);
}

._smallButton_lug8m_460 {
  font-size: var(--text-xs);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-base);
}

._secondaryButton_lug8m_446 {
  border: 1px solid var(--baseBorder);
  background-color: var(--baseSolidHover);
  color: var(--baseBase);
}

._dialogForm_lug8m_452 {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
}

._linkDialogEditForm_lug8m_478 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-2);
  padding: 0;
}

._linkDialogInputContainer_lug8m_486 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

._linkDialogInputWrapper_lug8m_492 {
  display: flex;
  align-items: center;
  background-color: var(--baseBase);

  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
}

._linkDialogInputWrapper_lug8m_492[data-visible-dropdown="true"] {
  border-bottom-left-radius: var(--radius-none);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-width: 0;
}

._linkDialogInputWrapper_lug8m_492 > button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding-right: var(--spacing-2);
}

._linkDialogInput_lug8m_486,
._dialogInput_lug8m_512 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
}

._linkDialogInput_lug8m_486::-moz-placeholder,
._dialogInput_lug8m_512::-moz-placeholder {
  color: var(--baseBorder);
}

._linkDialogInput_lug8m_486::placeholder,
._dialogInput_lug8m_512::placeholder {
  color: var(--baseBorder);
}

._linkDialogAnchor_lug8m_529 {
  position: fixed;
  background-color: highlight;
  z-index: -1;
}

._linkDialogAnchor_lug8m_529[data-visible="true"] {
  visibility: visible;
}

._linkDialogAnchor_lug8m_529[data-visible="false"] {
  visibility: hidden;
}

._linkDialogPopoverContent_lug8m_536,
._tableColumnEditorPopoverContent_lug8m_536,
._dialogContent_lug8m_536 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.2));
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius: var(--radius-extra-large);
  font-size: var(--text-sm);
}

._largeDialogContent_lug8m_548 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.2));
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBgSubtle);
  padding: var(--spacing-4);
  font-size: var(--text-sm);
}

._dialogTitle_lug8m_558 {
  font-size: var(--text-base);
  font-weight: 600;
  padding-left: var(--spacing-2);
}

._dialogCloseButton_lug8m_564 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  position: absolute;
  top: 10px;
  right: 10px;
}

._popoverContent_lug8m_571 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.2));
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  background-color: var(--baseBgSubtle);
  padding: var(--spacing-2) var(--spacing-2);
  font-size: var(--text-sm);
  z-index: 1;
}

._popoverArrow_lug8m_583 {
  fill: var(--baseBgSubtle);
}

._linkDialogPreviewAnchor_lug8m_589 {
  margin-right: var(--spacing-3);
  display: flex;
  align-items: flex-start;
  color: var(--accentText);
  text-decoration: none;
  border: 1px solid transparent;
}

._linkDialogPreviewAnchor_lug8m_589 svg {
  width: var(--spacing-5);
  height: var(--spacing-5);
  margin-left: var(--spacing-2);
}

._linkDialogPreviewAnchor_lug8m_589 span {
  max-width: 14rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._tooltipContent_lug8m_611 {
  z-index: 2;
  position: relative;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1) var(--spacing-2);
  font-size: var(--text-xs);
  background-color: var(--baseText);
  color: var(--baseBase);
}

._tooltipContent_lug8m_611 svg {
  fill: var(--baseText);
}

._actionButton_lug8m_625 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2);
  padding: var(--spacing-1) var(--spacing-1);
  border-radius: var(--radius-medium);
}

._actionButton_lug8m_625 svg {
  display: block;
}

._actionButton_lug8m_625:hover {
  background-color: var(--baseBgHover);
}

._actionButton_lug8m_625:active svg {
  transform: translate(1px, 1px);
}

._actionButton_lug8m_625[data-state="on"],
._actionButton_lug8m_625:active {
  background-color: var(--accentBgActive);
}

._primaryActionButton_lug8m_631 {
  background-color: var(--accentSolid);
  color: var(--baseBase);
}

._primaryActionButton_lug8m_631:hover {
  background-color: var(--accentSolidHover);
  color: var(--baseBase);
}

._tableEditor_lug8m_640 {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

._tableEditor_lug8m_640 thead > tr > th {
  text-align: right;
}

._tableEditor_lug8m_640 > tbody > tr > td:not(._toolCell_lug8m_651) {
  border: 1px solid var(--baseBorder);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: normal;
}

._tableEditor_lug8m_640 > tbody > tr > td:not(._toolCell_lug8m_651) > div {
  outline: none;
}

._tableEditor_lug8m_640 > tbody > tr > td:not(._toolCell_lug8m_651) > div > p {
  margin: 0;
}

._tableEditor_lug8m_640
  > tbody
  > tr
  > td[data-active="true"]:not(._toolCell_lug8m_651) {
  outline: solid 2px var(--accentSolid);
}

._tableEditor_lug8m_640 ._tableColumnEditorTrigger_lug8m_668,
._tableEditor_lug8m_640 ._tableRowEditorTrigger_lug8m_668,
._tableEditor_lug8m_642 ._addRowButton_lug8m_668,
._tableEditor_lug8m_640 ._addColumnButton_lug8m_668,
._tableEditor_lug8m_640 ._iconButton_lug8m_401 {
  opacity: 0.2;
}

._tableEditor_lug8m_640:hover ._tableColumnEditorTrigger_lug8m_668,
._tableEditor_lug8m_640:hover ._tableRowEditorTrigger_lug8m_668,
._tableEditor_lug8m_640:hover ._addRowButton_lug8m_668,
._tableEditor_lug8m_640:hover ._addColumnButton_lug8m_668,
._tableEditor_lug8m_640:hover ._iconButton_lug8m_401 {
  opacity: 0.5;
}

._tableEditor_lug8m_640:hover ._tableColumnEditorTrigger_lug8m_668:hover,
._tableEditor_lug8m_640:hover ._tableRowEditorTrigger_lug8m_668:hover,
._tableEditor_lug8m_640:hover ._addRowButton_lug8m_668:hover,
._tableEditor_lug8m_640:hover ._addColumnButton_lug8m_668:hover,
._tableEditor_lug8m_640:hover ._iconButton_lug8m_401:hover {
  opacity: 1;
}

._toolCell_lug8m_651 {
  text-align: right;
}

._toolCell_lug8m_651 button {
  margin: auto;
  display: block;
}

._tableColumnEditorTrigger_lug8m_668 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2);
  padding: var(--spacing-1);
  border-radius: var(--radius-full);
  opacity: 0.2;
}

._tableColumnEditorTrigger_lug8m_668 svg {
  display: block;
}

._tableColumnEditorTrigger_lug8m_668:hover {
  background-color: var(--baseBgHover);
}

._tableColumnEditorTrigger_lug8m_668:active svg {
  transform: translate(1px, 1px);
}

._tableColumnEditorTrigger_lug8m_668[data-state="on"],
._tableColumnEditorTrigger_lug8m_668:active {
  background-color: var(--accentBgActive);
}

._tableColumnEditorTrigger_lug8m_668[data-active="true"] {
  opacity: 1;
}

._tableColumnEditorToolbar_lug8m_702 {
  display: flex;
}

._tableColumnEditorToolbar_lug8m_702 > button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2);
}

._tableColumnEditorToolbar_lug8m_702 > button svg {
  display: block;
}

._tableColumnEditorToolbar_lug8m_702 > button:hover {
  background-color: var(--baseBgHover);
}

._tableColumnEditorToolbar_lug8m_702 > button:active svg {
  transform: translate(1px, 1px);
}

._tableColumnEditorToolbar_lug8m_702 > button[data-state="on"],
._tableColumnEditorToolbar_lug8m_702 > button:active {
  background-color: var(--accentBgActive);
}

._tableColumnEditorToolbar_lug8m_702 [role="separator"] {
  margin-left: var(--spacing-1);
  margin-right: var(--spacing-1);
}

._toggleGroupRoot_lug8m_714 {
  display: inline-flex;
}

._toggleGroupRoot_lug8m_714 button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2);
}

._toggleGroupRoot_lug8m_714 button svg {
  display: block;
}

._toggleGroupRoot_lug8m_714 button:hover {
  background-color: var(--baseBgHover);
}

._toggleGroupRoot_lug8m_714 button:active svg {
  transform: translate(1px, 1px);
}

._toggleGroupRoot_lug8m_714 button[data-state="on"],
._toggleGroupRoot_lug8m_714 button:active {
  background-color: var(--accentBgActive);
}

._toggleGroupRoot_lug8m_714 button:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}

._toggleGroupRoot_lug8m_714 button:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._tableToolsColumn_lug8m_731 {
  width: 3rem;
}

._tableToolsColumn_lug8m_731 button {
  margin: auto;
  display: block;
}

._leftAlignedCell_lug8m_739 {
  text-align: left;
}

._rightAlignedCell_lug8m_743 {
  text-align: right;
}

._centeredCell_lug8m_747 {
  text-align: center;
}

._addColumnButton_lug8m_668,
._addRowButton_lug8m_668 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2);
  background-color: var(--baseBase);

  display: flex;
  align-items: center;
  border-radius: var(--radius-medium);
}

._addColumnButton_lug8m_668 svg,
._addRowButton_lug8m_668 svg {
  display: block;
}

._addColumnButton_lug8m_668:hover,
._addRowButton_lug8m_668:hover {
  background-color: var(--baseBgHover);
}

._addColumnButton_lug8m_668:active svg,
._addRowButton_lug8m_668:active svg {
  transform: translate(1px, 1px);
}

._addColumnButton_lug8m_668[data-state="on"],
._addRowButton_lug8m_668[data-state="on"],
._addColumnButton_lug8m_664:active,
._addRowButton_lug8m_664:active {
  background-color: var(--accentBgActive);
}

._addColumnButton_lug8m_668 svg,
._addRowButton_lug8m_668 svg {
  margin: auto;
}

._addRowButton_lug8m_668 {
  width: 100%;
  margin-top: var(--spacing-2);
  box-sizing: border-box;
}

._addColumnButton_lug8m_668 {
  margin-left: var(--spacing-2);
  height: 100%;
}

/** Dialog */
._dialogOverlay_lug8m_775 {
  position: fixed;
  inset: 0;
  animation: _overlayShow_lug8m_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: var(--baseBase);
  z-index: 51;
  opacity: 0.5;
}

._dialogContent_lug8m_536,
._largeDialogContent_lug8m_548 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: _contentShow_lug8m_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 52;
}

._dialogContent_lug8m_536:focus,
._largeDialogContent_lug8m_548:focus {
  outline: none;
}

@keyframes _overlayShow_lug8m_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes _contentShow_lug8m_1 {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

._focusedImage_lug8m_818 {
  outline: highlight solid 2px;
}

._imageWrapper_lug8m_822 {
  display: inline-block;
  position: relative;
}

._imageWrapper_lug8m_822[draggable="true"] {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

._editImageButton_lug8m_834 {
  position: absolute;
  right: var(--spacing-2);
  top: var(--spacing-2);
  background: var(--baseBase);
  border-radius: var(--radius-full);
}

._editImageButton_lug8m_834 svg {
  display: block;
}

._inlineEditor_lug8m_846 {
  display: inline-flex;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  align-items: center;
  background: var(--baseBg);
}

._blockEditor_lug8m_855 {
  display: flex;
  justify-content: stretch;
  border-radius: var(--radius-medium);
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  align-items: center;
  background: var(--baseBg);
}

._blockEditor_lug8m_855 ._nestedEditor_lug8m_864 {
  flex-grow: 1;
}

._nestedEditor_lug8m_864 {
  background: white;
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-medium);
}

._nestedEditor_lug8m_864 > p {
  margin: 0;
}

._nestedEditor_lug8m_864:focus {
  outline: none;
}

._genericComponentName_lug8m_881 {
  font-size: var(--text-sm);
  color: var(--baseText);
  padding-right: var(--spacing-2);
}

._diffSourceToggle_lug8m_899 > :nth-child(2) {
  display: none;
}

._diffSourceToggle_lug8m_899 ._toolbarToggleItem_lug8m_186 {
  padding: 0;
}

._diffSourceToggle_lug8m_899 ._toolbarToggleItem_lug8m_186 > span {
  display: block;
  padding: var(--spacing-2) var(--spacing-3);
}

._selectWithLabel_lug8m_901 {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  margin-left: var(--spacing-2);
}

._selectWithLabel_lug8m_901 > label {
  font-size: var(--text-sm);
}

._selectWithLabel_lug8m_901 ._selectTrigger_lug8m_278,
._selectWithLabel_lug8m_901 ._selectTrigger_lug8m_345 {
  border: 1px solid var(--baseBorder);
}

._toolbarTitleMode_lug8m_915 {
  font-size: var(--text-sm);
  margin-left: var(--spacing-2);
}

._imageControlWrapperResizing_lug8m_921 {
  touch-action: none;
}

._imageResizer_lug8m_925 {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: var(--accentText);
  border: 1px solid var(--baseBg);
}

._imageResizer_lug8m_925._imageResizerN_lug8m_934 {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

._imageResizer_lug8m_925._imageResizerNe_lug8m_940 {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

._imageResizer_lug8m_925._imageResizerE_lug8m_946 {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

._imageResizer_lug8m_925._imageResizerSe_lug8m_952 {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

._imageResizer_lug8m_925._imageResizerS_lug8m_952 {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

._imageResizer_lug8m_925._imageResizerSw_lug8m_964 {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

._imageResizer_lug8m_925._imageResizerW_lug8m_970 {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

._imageResizer_lug8m_925._imageResizerNw_lug8m_976 {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

._placeholder_lug8m_993 {
  color: var(--baseSolid);
  overflow: hidden;
  position: absolute;
  top: 0;
  padding: var(--spacing-3);
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

._rootContentEditableWrapper_lug8m_1006 {
  position: relative;
  min-height: 300px;
  border: 1px solid #f2f4f7;
  margin-top: 10px;
  border-radius: 2px;
}

._downshiftContainer_lug8m_1004 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

._downshiftInputWrapper_lug8m_1007 {
  display: flex;
  align-items: center;
  background-color: var(--baseBase);

  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
}

._downshiftInputWrapper_lug8m_1007[data-visible-dropdown="true"] {
  border-bottom-left-radius: var(--radius-none);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-width: 0;
}

._downshiftInputWrapper_lug8m_1007 > button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding-right: var(--spacing-2);
}

._downshiftInput_lug8m_1007 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
}

._downshiftInput_lug8m_1007::-moz-placeholder {
  color: var(--baseBorder);
}

._downshiftInput_lug8m_1007::placeholder {
  color: var(--baseBorder);
}

._downshiftAutocompleteContainer_lug8m_1037 {
  position: relative;
}

._downshiftAutocompleteContainer_lug8m_1037 ul {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  font-size: var(--text-sm);
  width: 100%;
  display: none;
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  max-height: var(--spacing-48);
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--baseBorder);
  border-top-width: 0;
  background-color: var(--baseBase);
}

._downshiftAutocompleteContainer_lug8m_1037 ul[data-visible="true"] {
  display: block;
}

._downshiftAutocompleteContainer_lug8m_1037 ul li {
  padding: var(--spacing-2) var(--spacing-3);
  white-space: nowrap;
  margin-bottom: var(--spacing-1);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

._downshiftAutocompleteContainer_lug8m_1037 ul li[data-selected="true"] {
  background-color: var(--baseBgSubtle);
}

._downshiftAutocompleteContainer_lug8m_1037 ul li[data-highlighted="true"] {
  background-color: var(--baseBgHover);
}

._downshiftAutocompleteContainer_lug8m_1037 ul li:last-of-type {
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}

._textInput_lug8m_1082 {
  all: unset;
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  padding: var(--spacing-2) var(--spacing-3);
}

form._multiFieldForm_lug8m_1092 {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-8) var(--spacing-4);
  gap: var(--spacing-6);
  background-color: white;
  border-radius: 6px;
}

form._multiFieldForm_lug8m_1092 label {
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: 700;
  color: #344054;
}

form._multiFieldForm_lug8m_1092 button:first-child {
  background-color: #344054;
  color: white;
}

form._multiFieldForm_lug8m_1092 button:nth-child(2) {
  background-color: white;
  color: black;
}

input[type="file"]::file-selector-button {
  border: 1px solid #eaecf0;
  padding: 0.4em 0.8em;
  border-radius: 0.2em;
  background-color: #ffffff;
  margin-right: 10px;
}

form._multiFieldForm_lug8m_1092 ._formField_lug8m_1096 {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}
